import { defineStore } from 'pinia'
import { Howl } from 'howler'
import type { components } from '~/interfaces/api/api'

type Course = components['schemas']['Course']
type Mentor = components['schemas']['Client']
type Progress = components['schemas']['Progress']

export interface PlaylistAudio {
  id: string
  index: number
  isMainAudio: boolean
  hasAccess: boolean
  logoUrl: string
  logoUrlOverview: string
  slug: string
  title: string
  type: string
  descriptionLong: string
  notes: string
  repliesCount: number
  duration: number
  formattedDuration: string
  audioUrl: string
  attachments: []
  mentor: Mentor
  progress: Progress
  audioElement: Howl | null
  isLoading: boolean
  isLoadError: boolean
  isPlayError: boolean
  isPlaying: boolean
  isError: boolean
  currentProgressInPercent: number
  currentTimeInSec: number | 0
  timeRemainingInSec: number
  formattedTime: string
  formattedTimeRemaining: string
  listeningTime: number
  listeningTimeStart: number
  listeningTimeEnd: number
  totalSeekTime: number
}

export interface Playlist {
  id: string
  isActive: boolean
  type: string
  course: Course
  currentIndex: number
  audios: PlaylistAudio[]
}

interface AudioPlayerNewStore {
  settings: {
    hidePlayer: boolean
    redirectToCourseOnClickDisabled: boolean
    freeLessonsDisabled: boolean
    lockedTarget: {
      type: 'modal' | 'page' | 'section'
      id: string
    }
  }
  playlists: Playlist[]
  currentAudio: PlaylistAudio
  playbackRate: number | 1
  isFullPlayerOpen: boolean
}

export const useAudioPlayerNewStore = defineStore('audioPlayerNew', {
  state: () => {
    return {
      settings: {
        hidePlayer: false,
        redirectToCourseOnClickDisabled: false,
        freeLessonsDisabled: false,
        lockedTarget: {
          type: 'page',
          id: '/membership',
        },
      },
      playlists: [],
      currentAudio: {} as PlaylistAudio,
      playbackRate: 1,
      isFullPlayerOpen: false,
    } as AudioPlayerNewStore
  },
  actions: {
    initSettings(blok: any) {
      // Save Audio Player Settings
      if (blok.player_settings?.length > 0) {
        const audioPlayerSettings = blok.player_settings[0]
        if (audioPlayerSettings) {
          this.settings.hidePlayer = audioPlayerSettings.hide_player
          this.settings.redirectToCourseOnClickDisabled = audioPlayerSettings.redirect_to_course_on_click_disabled
          this.settings.freeLessonsDisabled = audioPlayerSettings.free_lessons_disabled

          const lockedTarget = audioPlayerSettings.locked_target[0]
          if (lockedTarget) {
            this.settings.lockedTarget = {
              type: lockedTarget.type,
              id: lockedTarget.id,
            }
          }
        }
      }
      else {
        this.settings = {
          hidePlayer: false,
          redirectToCourseOnClickDisabled: false,
          freeLessonsDisabled: false,
          lockedTarget: {
            type: 'page',
            id: '/membership',
          },
        }
      }
    },
    showPlayer() {
      this.settings.hidePlayer = false
    },
    hidePlayer() {
      this.settings.hidePlayer = true
    },
    openLockedLessonCta() {
      const localePath = useLocalePath()
      if (this.settings.lockedTarget.type === 'page') { navigateTo(localePath(this.settings.lockedTarget.id)) }

      if (this.settings.lockedTarget.type === 'section') {
        const section = document.getElementById(this.settings.lockedTarget.id)
        section?.scrollIntoView({ behavior: 'smooth' })
      }

      if (this.settings.lockedTarget.type === 'modal') { useModalStore().openModal(this.settings.lockedTarget.id) }
    },
    activatePlaylist(playlistId: string) {
      if (this.getCurrentPlaylist) { this.getCurrentPlaylist.isActive = false }

      this.playlists.filter(playList => playList?.id === playlistId)[0].isActive = true
    },
    toggleCurrentAudio(fromNextOrPrev: boolean = false) {
      if (this.currentAudio.type === 'intro' || this.getCurrentPlaylist.course?.has_access || (this.currentAudio.hasAccess && !this.settings.freeLessonsDisabled)) { this._toggleOrInitializeAudio() }
      else if (!fromNextOrPrev) { this.openLockedLessonCta() }
    },
    toggleAudioByIds(playlistId: string, audioId: string) {
      if (this.currentAudio.id !== audioId && this.currentAudio.isPlaying) { this.toggleCurrentAudio() }

      this.activatePlaylist(playlistId)
      this.currentAudio = this.getAudioByIds(playlistId, audioId) || {} as PlaylistAudio
      // check if audio is already initialized
      this._toggleOrInitializeAudio()
    },
    togglePlaybackRate() {
      const playbackRateControls = [0.5, 0.75, 1.0, 1.25, 1.5, 2.0]

      this.playbackRate = playbackRateControls[(playbackRateControls.indexOf(this.playbackRate) + 1) % playbackRateControls.length]
      if (this.currentAudio.audioElement) { this.currentAudio.audioElement.rate(this.playbackRate) }
    },
    seekAudio(time: number) {
      if (this.currentAudio.audioElement) {
        const seekTime = (this.currentAudio.audioElement.seek() + time) | 0
        if (seekTime < 0) { this.currentAudio.audioElement.seek(0) }
        else { this.currentAudio.audioElement.seek(seekTime) }
      }
    },
    seekAudioToPercent(progressInPercent: number) {
      if (this.currentAudio.hasAccess && this.currentAudio.audioElement) {
        const currentSeek = this.currentAudio.audioElement.seek()
        const newSeek = this.currentAudio.duration * progressInPercent / 100
        const seekTime = Math.abs(currentSeek + newSeek)
        this.currentAudio.totalSeekTime = this.currentAudio.totalSeekTime + seekTime
        this.currentAudio.audioElement.seek(newSeek)
      }
    },
    prevAudio() {
      if (this.hasPrevAudio) {
        // pause current audio
        if (this.currentAudio.audioElement) { this.currentAudio.audioElement.pause() }

        this.currentAudio = this.getCurrentPlaylist.audios[this.currentAudio.index - 1]
        this.getCurrentPlaylist.currentIndex = this.currentAudio.index
        this.toggleCurrentAudio(true)
      }
    },
    nextAudio() {
      if (this.hasNextAudio) {
        // pause current audio
        if (this.currentAudio.audioElement) { this.currentAudio.audioElement.pause() }

        this.currentAudio = this.getCurrentPlaylist.audios[this.currentAudio.index + 1]
        this.getCurrentPlaylist.currentIndex = this.currentAudio.index
        this.toggleCurrentAudio(true)
      }
    },
    addOrUpdatePlaylist(playlist: Playlist) {
      const existingPlaylist = this.playlists.find(p => p.id === playlist.id)

      if (existingPlaylist) {
        // update existing playlist and add new audios
        playlist.audios.forEach((audio) => {
          if (!existingPlaylist.audios.find(a => a.id === audio.id)) { existingPlaylist.audios.push(audio) }
        })
      }
      else {
        this.playlists.push(playlist)
      }
    },
    async trackProgress(audio: PlaylistAudio, finished: boolean) {
      const authStore = useAuthStore()
      if (authStore.isAuthenticated && !audio.isMainAudio) {
        this._calculateListeningTime(audio)
        const progressObject = {
          progressable_type: 'Course::Lesson',
          progressable_id: audio.id,
          listening_time: audio.listeningTime,
          current_position: Math.round(audio.audioElement!.seek()),
          tracked_at: new Date(),
          finished: finished.toString(),
        }

        const progressList = { progress_list: [progressObject] }

        // @ts-expect-error - this is a workaround for the $api type
        await useNuxtApp().$api.progress.postProgress(progressList)
        this._resetListeningTime(audio)
      }
    },
    _calculateListeningTime(audio: PlaylistAudio) {
      const startTime = audio.listeningTimeStart
      let currentTime = 0
      if (audio.audioElement) { currentTime = audio.audioElement.seek() }

      const totalSeekTime = audio.totalSeekTime
      const listeningTime = Math.abs(startTime - (currentTime - totalSeekTime))
      audio.listeningTime = Math.round(listeningTime)
    },
    _resetListeningTime(audio: PlaylistAudio) {
      audio.listeningTime = 0
      if (audio.audioElement) {
        audio.listeningTimeStart = Math.round(audio.audioElement.seek())
        audio.listeningTimeEnd = Math.round(audio.audioElement.seek())
      }
      audio.totalSeekTime = 0
    },
    _initializeAudio() {
      const currentAudio: PlaylistAudio = this.currentAudio
      const sound = new Howl({
        src: [currentAudio.audioUrl],
        html5: true,
        rate: this.playbackRate,
        onload: () => {
          sound.seek(currentAudio.listeningTimeStart)
          if (!sound.playing()) { sound.play() }

          currentAudio.isError = false
        },
        onplay: () => {
          window.requestAnimationFrame(this._updateCurrentTimes)
          this.getCurrentPlaylist.currentIndex = currentAudio.index
          currentAudio.isPlaying = true
          currentAudio.isLoading = false
        },
        onpause: () => {
          this.trackProgress(currentAudio, false)
          currentAudio.isPlaying = false
        },
        onseek: () => {
          if (currentAudio.audioElement) { currentAudio.totalSeekTime = Math.round(currentAudio.audioElement.seek()) }
        },
        onend: () => {
          this.trackProgress(currentAudio, true)
          currentAudio.isPlaying = false
          this.nextAudio()
        },
        onloaderror: () => {
          const { notify } = useNotifications()
          notify({
            title: 'Audio could not be loaded',
            description: 'Please try again. If the problem persists, please contact us at support@beyondbreath.com',
            type: 'error',
          }, 4000)

          currentAudio.isError = true
          currentAudio.isLoading = false
        },
        onplayerror: () => {
          const { notify } = useNotifications()
          notify({
            title: 'Audio could not be played',
            description: 'Please try again. If the problem persists, please contact us at support@beyondbreath.com',
            type: 'error',
          }, 4000)

          currentAudio.isError = true
          currentAudio.isLoading = false
        },
      })
      this.currentAudio.audioElement = sound
      this.currentAudio.isLoading = true
    },
    _toggleOrInitializeAudio() {
      if (this.currentAudio.isError || (this.currentAudio.audioElement === null && typeof this.currentAudio.audioUrl !== 'undefined')) { this._initializeAudio() }
      else if (this.currentAudio.audioElement) { this._toggleAudio() }
    },
    _toggleAudio() {
      this.showPlayer()
      if (this.currentAudio.isPlaying) {
        this.currentAudio.isLoading = false
        if (this.currentAudio.audioElement) { this.currentAudio.audioElement.pause() }
      }
      else {
        this.currentAudio.isLoading = true
        if (this.currentAudio.audioElement) { this.currentAudio.audioElement.play() }
      }
    },
    _updateCurrentTimes() {
      // Return if currentAudio is not set
      if (!this.currentAudio) { return }

      if (this.currentAudio.audioElement === null) {
        this.currentAudio.duration = 0
        this.currentAudio.currentTimeInSec = 0
        this.currentAudio.timeRemainingInSec = 0
        this.currentAudio.currentProgressInPercent = 0
        this.currentAudio.formattedTimeRemaining = this._formatTime(this.currentAudio.timeRemainingInSec)
        this.currentAudio.formattedTime = this._formatTime(this.currentAudio.currentTimeInSec)
      }
      else {
        if (this.currentAudio.audioElement) {
          this.currentAudio.duration = this.currentAudio.audioElement.duration() || 0
          this.currentAudio.currentTimeInSec = this.currentAudio.audioElement.seek() || 0
        }
        this.currentAudio.timeRemainingInSec = this.currentAudio.duration - this.currentAudio.currentTimeInSec
        this.currentAudio.currentProgressInPercent = this.currentAudio.currentTimeInSec / this.currentAudio.duration * 100
        this.currentAudio.formattedTimeRemaining = this._formatTime(this.currentAudio.timeRemainingInSec)
        this.currentAudio.formattedTime = this._formatTime(this.currentAudio.currentTimeInSec)
      }
      if (this.currentAudio.isPlaying) {
        requestAnimationFrame(this._updateCurrentTimes)
      }
    },
    _formatTime(seconds: number) {
      const minutes = Math.floor(seconds / 60) || 0
      const sec = (seconds - minutes * 60) || 0

      return `${minutes}:${Math.round(sec) < 10 ? '0' : ''}${Math.round(sec)}`
    },
  },
  getters: {
    getCurrentPlaylist: state => state.playlists.filter(playList => playList?.isActive)[0],
    getCurrentAudio: state => state.currentAudio,
    getAudioByIds: (state) => {
      return (playlistId: string, audioId: string) => state.playlists
        .find(playlist => playlist.id === playlistId)?.audios
        .find(audio => audio.id === audioId)
    },
    hasNextAudio() {
      const currentPlaylist: Playlist = this.getCurrentPlaylist
      return currentPlaylist && currentPlaylist.currentIndex < currentPlaylist.audios.length - 1
    },
    hasPrevAudio() {
      const currentPlaylist: Playlist = this.getCurrentPlaylist
      return currentPlaylist && currentPlaylist.currentIndex > 0
    },
  },
})
